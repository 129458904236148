import { IconUKFlag, IconUSFlag, IconChinaFlag, IconMexicoFlag } from "icons";
import EnglandMapImg from "assets/mapImgs/england-location-map.png";
import USMapImg from "assets/mapImgs/us-location-map.png";
import ChinaMapImg from "assets/mapImgs/china-location-map.png";
import MexicoMapImg from "assets/mapImgs/mexico-location-map.png";

// Location building and space images
import InternationalLocationImg from "assets/locations/international.png";
import USALocationImg from "assets/locations/USA.png";
import ChinaLocationImg from "assets/locations/China.png";
import MexicoLocationImg from "assets/locations/Mexico.png";
import IndiaLocationImg from "assets/locations/India.png";
import JapanLocationImg from "assets/locations/Japan.png";
import CzechLocationImg from "assets/locations/Czech.png";
import BulgariaLocationImg from "assets/locations/Bulgaria.png";
import USA2LocationImg from "assets/locations/USA2.png";
import IsraelLocationImg from "assets/locations/Israel.png";
import CaliforniaLocationImg from "assets/locations/California.png";
import USA3LocationImg from "assets/locations/USA3.png";
import ItalyLocationImg from "assets/locations/Italy.png";
import USAArvadaLocationImg from "assets/locations/USAArvada.png";
import USAHaywardLocationImg from "assets/locations/USAHayward.png";
import PolandLocationImg from "assets/locations/Poland.png";
import VietnamLocationImg from "assets/locations/Vietnam.png";
import USAAustinLocationImg from "assets/locations/USAAustin.png";
import RussiaPeterburgLocationImg from "assets/locations/RussiaPeterburg.png";
import USADownersGroveLocationImg from "assets/locations/USADownersGrove.png";
import HungaryLocationImg from "assets/locations/Hungary.png";
import USAOdessaLocationImg from "assets/locations/USAOdessa.png";
import ItalyCantuLocationImg from "assets/locations/ItalyCantu.png";
import USAWakeForestLocationImg from "assets/locations/USAWakeForest.png";
import TaiwanLocationImg from "assets/locations/Taiwan.png";
import HongKongLocationImg from "assets/locations/HongKong.png";
import USAPortlandLocationImg from "assets/locations/USAPortland.png";
import TaiwanNewTaipeiLocationImg from "assets/locations/TaiwanNewTaipei.png";
import USAWabanLocationImg from "assets/locations/USAWaban.png";
import MexicoJaliscoLocationImg from "assets/locations/MexicoJalisco.png";
import SauthAfricaLocationImg from "assets/locations/SauthAfrica.png";
import KoreaLocationImg from "assets/locations/Korea.png";
import ThailandLocationImg from "assets/locations/Thailand.png";
import AustraliaLocationImg from "assets/locations/Australia.png";
import USALiverpoolLocationImg from "assets/locations/USALiverpool.png";
import GermanyNeubulachLocationImg from "assets/locations/GermanyNeubulach.png";
import TurkeyLocationImg from "assets/locations/Turkey.png";
import USAHuntsvilleLocationImg from "assets/locations/USAHuntsville.png";
import USACaliforniaGeneralLocationImg from "assets/locations/USACaliforniaGeneral.png";
import PortugalLocationImg from "assets/locations/Portugal.png";
import USAScottsdaleLocationImg from "assets/locations/USAScottsdale.png";
import GermanyBendorfLocationImg from "assets/locations/GermanyBendorf.png";
import FinlandLocationImg from "assets/locations/Finland.png";
import BelgiumLocationImg from "assets/locations/Belgium.png";
import CanadaOntarioLocationImg from "assets/locations/CanadaOntario.png";
import AtibaiaLocationImg from "assets/locations/Atibaia.png";
import USAElPasoLocationImg from "assets/locations/USAElPaso.png";
import PolandKrakowLocationImg from "assets/locations/PolandKrakow.png";
import CebuCityLocationImg from "assets/locations/CebuCity.png";
import KoreaSeoulLocationImg from "assets/locations/KoreaSeoul.png";
import USAMuriettaLocationImg from "assets/locations/USAMurietta.png";
import SwedenLocationImg from "assets/locations/Sweden.png";

export const COUNTRY_LOCATION_DETAILS = {
  uk: {
    flag: <IconUKFlag />,
    mapImg: EnglandMapImg,
    name: "UK",
    phone: "+44 (0)1245 491333",
    email: "sales@pillarhouse.co.uk",
    address:
      "Pillarhouse International Ltd Rodney Way Chelmsford Essex CM1 3BY UK",
    mapHTMLSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2471.6616746090567!2d0.44731857663286767!3d51.72093197186264!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8ea4a1481c2f1%3A0xd26482868950cc5b!2sRodney%20Way%2C%20Chelmsford%20CM1%203BY%2C%20UK!5e0!3m2!1sen!2sam!4v1712572373279!5m2!1sen!2sam",
    mapUrl:
      "https://www.google.com/maps/place/Rodney+Way,+Chelmsford+CM1+3BY,+UK/@51.720932,0.4473186,17z/data=!3m1!4b1!4m6!3m5!1s0x47d8ea4a1481c2f1:0xd26482868950cc5b!8m2!3d51.720932!4d0.4498935!16s%2Fg%2F1tdj3gw9?entry=ttu",
  },
  us: {
    flag: <IconUSFlag />,
    mapImg: USMapImg,
    name: "US",
    phone: "+1 847 593 9080",
    email: "sales@pillarhouse.co.uk",
    address:
      "Pillarhouse USA Inc. 201 Lively Boulevard Elk Grove Village IL 60007",
    mapHTMLSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2963.9187224185166!2d-87.97112972390796!3d42.023475971226254!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x405d082c835451fb%3A0x9f11d992660a985f!2sPillarhouse%20USA%20Inc.!5e0!3m2!1sen!2sam!4v1712572425724!5m2!1sen!2sam",
    mapUrl:
      "https://www.google.com/maps/place/Pillarhouse+USA+Inc./@42.023476,-87.9711297,17z/data=!3m1!4b1!4m6!3m5!1s0x405d082c835451fb:0x9f11d992660a985f!8m2!3d42.023476!4d-87.9685548!16s%2Fg%2F11b7g326sz?entry=ttu",
  },
  china: {
    flag: <IconChinaFlag />,
    mapImg: ChinaMapImg,
    name: "China",
    phone: "+86 (0)512 65860460",
    email: "sales@pillarhouse.co.uk",
    address:
      "Pillarhouse Soldering Systems (Suzhou) Co. Ltd. 11 Yuanqi Road Xiangcheng Suzhou China 215131",
    mapHTMLSrc:
      "https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d435710.6241852466!2d120.27930857045554!3d31.441962851070226!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sPillarhouse%20Soldering%20Systems%20Co.%20Ltd.%2011%20Yuanqi%20Road%20Xiangcheng%20Suzhou%20China%20215131!5e0!3m2!1sen!2sam!4v1712572460564!5m2!1sen!2sam",
    mapUrl:
      "https://www.google.com/maps/search/Pillarhouse+Soldering+Systems+(Suzhou)+Co.+Ltd.+11+Yuanqi+Road+Xiangcheng+Suzhou+China+/@31.40625,120.6317651,17z/data=!3m1!4b1?entry=ttu",
  },
  mexico: {
    flag: <IconMexicoFlag />,
    mapImg: MexicoMapImg,
    name: "Mexico",
    phone: "+52 33 3156 5653",
    email: "sales@pillarhouse.co.uk",
    address:
      "Pillarhouse Mexico MMS Av. Santa Catalina 1041 Zapopan Jalisco CP 45050 Mexico",
    mapHTMLSrc:
      "https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d238881.2006681067!2d-103.56826280205885!3d20.68914880429691!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sPillarhouse%20Mexico%20MMS%20Av.%20Santa%20Catalina%201041%20Zapopan%20Jalisco%20CP%2045050%20Mexico!5e0!3m2!1sen!2sam!4v1712572491052!5m2!1sen!2sam",
    mapUrl:
      "https://www.google.com/maps/search/Pillarhouse+Mexico+MMS+Av.+Santa+Catalina+1041+Zapopan+Jalisco+CP+Mexico/@20.657895,-103.4212816,17z/data=!3m1!4b1?entry=ttu",
  },
};

export const LANGUAGES = [
  { name: "UK", code: "en", flag: <IconUKFlag /> },
  { name: "China", code: "zh", flag: <IconChinaFlag /> },
];

export const PRODUCTS_FOR_CONFIGURATOR = [
  {
    name: "Synchrodex",
    nameInConfigurator: "Synchrodex Solder",
    cellsCount: 1,
  },
  {
    name: "Orissa Synchrodex Pro",
    nameInConfigurator: "Synchrodex Single Solder",
    cellsCount: 1,
  },
  {
    name: "Orissa Synchrodex Combo",
    nameInConfigurator: "Synchrodex Twin Solder",
    cellsCount: 2,
  },
  { name: "Fusion Layouts", nameInConfigurator: "Fusion" },
];

export const LOCATIONS = [
  {
    id: 1,
    name: "Pillarhouse International Ltd",
    address: "Rodney Way Chelmsford Essex CM1 3BY UK",
    representative: "John Doe",
    phone: "+1 847 593 9080",
    fax: "+1 847 593 9084",
    website: "www.pillarhouseusa.com",
    email: "sales@pillarhouseusa.com",
    image: InternationalLocationImg,
  },
  {
    id: 2,
    name: "Pillarhouse USA Inc.",
    address: "201 Lively Boulevard Elk Grove Village IL 60007 USA",
    representative: "John Doe",
    phone: "+1 847 593 9080",
    fax: "+1 847 593 9084",
    website: "www.pillarhouseusa.com",
    email: "sales@pillarhouseusa.com",
    image: USALocationImg,
  },
  {
    id: 3,
    name: "Pillarhouse Soldering Systems (Suzhou) Co. Ltd.",
    address: "11 Yuanqi Road Xiangcheng Suzhou China 215131",
    representative: "John Doe",
    phone: "+1 847 593 9080",
    fax: "+1 847 593 9084",
    website: "www.pillarhouseusa.com",
    email: "sales@pillarhouseusa.com",
    image: ChinaLocationImg,
  },
  {
    id: 4,
    name: "Pillarhouse Mexico MMS",
    address: "Av. Santa Catalina 1041 Zapopen Jalisco CP 45050 Mexico",
    representative: "John Doe",
    phone: "+1 847 593 9080",
    fax: "+1 847 593 9084",
    website: "www.pillarhouseusa.com",
    email: "sales@pillarhouseusa.com",
    image: MexicoLocationImg,
  },
  {
    id: 5,
    name: "Accurex Solutions (P) Ltd",
    address:
      "#782. 3rd Main. 1st Cross., BEML Layout, 5th Stage, Rajarajeswarinagar, Bangalore 560098, India",
    representative: "Mr K. N. Rammohan",
    image: IndiaLocationImg,
  },
  {
    id: 6,
    name: "Alpha Electronics Corp.",
    address:
      "3-20-21, Minami-Hatogaya, Kawaguchi-shi, Saitama, 334-0013, Japan",
    representative: "Jun Yoshino",
    image: JapanLocationImg,
  },
  {
    id: 7,
    name: "Amtech spol. s r.o.",
    address: "Bohuslava Martinů, 941/41b, 602 00 Brno, Czech Republic",
    representative: "Radek Nekarda",
    image: CzechLocationImg,
  },
  {
    id: 8,
    name: "Amtest EOOD",
    address: "Blvd. Arsenalski 81 V,, app. 41,, Sofia, 1421, Bulgaria",
    representative: "Pavlin Kozarov",
    image: BulgariaLocationImg,
  },
  {
    id: 9,
    name: "Argo-Zeta",
    address: "PO Box 998 - 2320 Trumbauersville Road, Quakertown, PA 18951 USA",
    representative: "Bob Slusarczyk",
    image: USA2LocationImg,
  },
  {
    id: 10,
    name: "ASI Technologies",
    address: "Netser-Sereni, 70395, Israel",
    representative: "Mr Kobi Ventura",
    image: IsraelLocationImg,
  },
  {
    id: 11,
    name: "Assembly Resource",
    address: "43835 North Moray Street, Fremont, California, 94539-5940",
    representative: "Leigh Jackson",
    image: CaliforniaLocationImg,
  },
  {
    id: 12,
    name: "BarTron Inc.",
    address: "2646 Riverside Dr., Trenton,, MI  48183 USA",
    representative: "Bob Papp",
    image: USA3LocationImg,
  },
  {
    id: 13,
    name: "Cabiotec SRL",
    address: "Via Romolo Bitti 6,, 20125 Milano, Italy",
    representative: "Carla Fiorentino",
    image: ItalyLocationImg,
  },
  {
    id: 14,
    name: "Cluff & Associates",
    address: "8795 Ralston Road, Suite 101, Arvada, CO 80002 USA",
    representative: "Sharon Cluff",
    image: USAArvadaLocationImg,
  },
  {
    id: 15,
    name: "Cornerstone Technical Marketing",
    address: "134 Turlock Way, Hayward, California, 94545 USA",
    representative: "Léo Huerta",
    image: USAHaywardLocationImg,
  },
  {
    id: 16,
    name: "CPS Industrial Engineering Poland Ltd",
    address: "Dluga 47 Mareza, 82-500 Kwidzyn, Poland",
    representative: "Michał Katana",
    image: PolandLocationImg,
  },
  {
    id: 17,
    name: "Creative Engineering Co. Ltd",
    address:
      "9 Nam Hoa Street, Phuoc Long A Ward,, District 9, Ho Chi Minh City, Vietnam",
    representative: "Tran Khoa Tien (Kevin)",
    image: VietnamLocationImg,
  },
  {
    id: 18,
    name: "DG Marketing",
    address: "16238 RR 620 North, Suite F-382, Austin, TX 78717 USA",
    representative: "Kris Evans",
    image: USAAustinLocationImg,
  },
  {
    id: 19,
    name: "Dipaul",
    address: "ul. Professora Popova 23A, St. Petersburg, 197376, Russia",
    representative: "Anton Sizov",
    image: RussiaPeterburgLocationImg,
  },
  {
    id: 20,
    name: "E-Tronix",
    address: "1441 Branding Lane, Suite 110, Downers Grove, IL 60515 USA",
    representative: "Anthony Noto",
    image: USADownersGroveLocationImg,
  },
  {
    id: 21,
    name: "Elas Kft",
    address: "Diofa utca 130, Budapest, H-1162, Hungary",
    representative: "Zoltan Szilassi",
    image: HungaryLocationImg,
  },
  {
    id: 22,
    name: "Electronic Assembly Products",
    address: "16207 Carnoustie Drive, Odessa, FL, 33556 USA",
    representative: "Mark Hendron",
    image: USAOdessaLocationImg,
  },
  {
    id: 23,
    name: "Grassi Trade s.r.l.",
    address: "Via Trieste, 5, 22063 Cantù (CO), Italy",
    representative: "Paolo Grassi",
    image: ItalyCantuLocationImg,
  },
  {
    id: 24,
    name: "I-Tech",
    address: "7101 Cove Lake Drive, Wake Forest, NC 27587 USA",
    representative: "Larry Coole",
    image: USAWakeForestLocationImg,
  },
  {
    id: 25,
    name: "Island Tek International Ltd",
    address:
      "5F., No. 369, Fude 3rd Rd., Xizhi Dist.,, New Taipei City, 22151, Taiwan",
    representative: "Mars Chu",
    image: TaiwanLocationImg,
  },
  {
    id: 26,
    name: "Kasion Automation Ltd.",
    address:
      "Suite 2602-2603, 26/F, New Tech Plaza,, No. 34 Tai Yau Street,San Po Kong,, Kowloon, Hong Kong",
    representative: "Mr Abby Tsoi",
    image: HongKongLocationImg,
  },
  {
    id: 27,
    name: "Kirby & Demarest",
    address: "515 NW Saltzman Rd. Ste. 882, Portland, OR 97229 USA",
    representative: "Steve Kirby",
    image: USAPortlandLocationImg,
  },
  {
    id: 28,
    name: "LoyalSun Tech Co. Ltd",
    address:
      "1F, No. 130, Jian Kang Road,, Jhonghe District, New Taipei City, Taiwan",
    representative: "Mr Hank Tseng",
    image: TaiwanNewTaipeiLocationImg,
  },
  {
    id: 29,
    name: "Matthew Associates Inc.",
    address: "92 Crofton Road, Waban, MA 02468-2115 USA",
    representative: "Clint Buldrini",
    image: USAWabanLocationImg,
  },
  {
    id: 30,
    name: "Mexico Maquila Services",
    address:
      "Av. Santa Catalina 1041. Col. Prados Tepeyac, Zapopan, Jalisco, CP 45050 Mexico",
    representative: "Francisco Rosas",
    image: MexicoJaliscoLocationImg,
  },
  {
    id: 31,
    name: "MyKay Tronics",
    address:
      "27 Newquay Road, New Redruth, Alberton, Johannesburg, South Africa",
    representative: "Dean Rodger",
    image: SauthAfricaLocationImg,
  },
  {
    id: 32,
    name: "Nealon",
    address:
      "NPLab Center 5th Floor, 32-33, LS-ro, 91beon-gil,, Dongan-Gu, Anyang-City, Gyeonggi-Do, Korea, 14119",
    representative: "Huichul Cho",
    image: KoreaLocationImg,
  },
  {
    id: 33,
    name: "Niche Tech (2004) Co. Ltd",
    address:
      "90/120 Moo 6 Soi Thaesaban 2, Buathong Factory 2, Bangkruay-Sainoi Rd., Bangrakyai, Bangbuathong, Nonthaburi 11110, Thailand",
    representative: "Phermpoon Ekpho",
    image: ThailandLocationImg,
  },
  {
    id: 34,
    name: "ONBoard Solutions",
    address: "2 Salisbury Street, Botany, Sydney, NSW 2019, Australia",
    representative: "Joshua Lawson",
    image: AustraliaLocationImg,
  },
  {
    id: 35,
    name: "Performance Technologies",
    address: "Liverpool, NY 13090 USA",
    representative: "Andy Bresnahan",
    image: USALiverpoolLocationImg,
  },
  {
    id: 37,
    name: "Pillarhouse-Maschinen",
    address: "Vertriebs-GmbH & C, Buhlstrasse 12, D-75387 Neubulach 1, Germany",
    representative: "Mr Hans Stockler",
    image: GermanyNeubulachLocationImg,
  },
  {
    id: 38,
    name: "Pro SMT Elektronik A.Ş.",
    address:
      "Yesilbaglar Mah. Selvili Sok. Helis Beyaz Office A Block 2/1, 13 Pendik, Istanbul, Turkey",
    representative: "Hüseyin Yilmaz",
    image: TurkeyLocationImg,
  },
  {
    id: 39,
    name: "Process Automation & Tool, LLC",
    address: "177 Nick Fitcheard Road, Huntsville, AL 35806 USA",
    representative: "Todd Gilmore",
    image: USAHuntsvilleLocationImg,
  },
  {
    id: 40,
    name: "Production Specialties",
    address: "CA",
    representative: "Bill Arnold",
    image: USACaliforniaGeneralLocationImg,
  },
  {
    id: 41,
    name: "ProMasTech Lda",
    address:
      "Parque Empresarial Brejo do Lobo, Rua dos Tractores nº 506, Edificio C,, 2870-607 Alto do Estanque, Portugal",
    representative: "Marco Leite",
    image: PortugalLocationImg,
  },
  {
    id: 42,
    name: "Rich Sales",
    address: "15547 N. 77th Street, Scottsdale, AZ 85260 USA",
    representative: "Dave Heden",
    image: USAScottsdaleLocationImg,
  },
  {
    id: 43,
    name: "Rubröder GmbH Factory Automation",
    address: "Theodor-Neizert-Strasse 1, D-56170 Bendorf, Germany",
    representative: "Mr Wolfgang Riedel",
    image: GermanyBendorfLocationImg,
  },
  {
    id: 44,
    name: "Sincotron Finland Oy",
    address: "Nihtisillankuja 3 B, 02630 Espoo, Finland",
    representative: "Pehr Nordman",
    image: FinlandLocationImg,
  },
  {
    id: 45,
    name: "SMD-Tec",
    address: "9de Liniestraat 23 D, Aarschot, 3200, Belgium",
    representative: "Tom Van Tongelen",
    image: BelgiumLocationImg,
  },
  {
    id: 46,
    name: "SMT Industrial Supply",
    address: "3-200 Memorial Ave, Suite 202, Orillia, Ontario, L3V 5X6, Canada",
    representative: "Geoff Zacour",
    image: CanadaOntarioLocationImg,
  },
  {
    id: 47,
    name: "SMTech Technosolder",
    address:
      "Belém do Pará Street, 298 - Playground Estoril, Atibaia / SP, 12944-120",
    representative: "Sergio R Amico",
    image: AtibaiaLocationImg,
  },
  {
    id: 48,
    name: "SMTechnoReps",
    address: "14005 Sandy Point Ln, El Paso, TX 79938 USA",
    representative: "Eduardo Valles",
    image: USAElPasoLocationImg,
  },
  {
    id: 49,
    name: "Sowa Electronics",
    address: "Agatowa 37A/25, 30-798 Kraków, Poland",
    representative: "Karol Sowa",
    image: PolandKrakowLocationImg,
  },
  {
    id: 50,
    name: "Trans-Tec International Marketing Phils., Inc.",
    address:
      "Unit 506 Ayala Life FGU Ctr., Mindance Ave Cor Biliran Road, Cebu Business Park, Cebu City",
    representative: "Samuel Chen",
    image: CebuCityLocationImg,
  },
  {
    id: 51,
    name: "UnoTech Inc.",
    address:
      "#404 B-Dong SsangYong IT Twin tower, 537, Dunchon-daero, Jungwon-gu, Seongnam-si, Gyeonggi-do,, Seoul 462-723, Korea",
    representative: "Harry Kwon",
    image: KoreaSeoulLocationImg,
  },
  {
    id: 52,
    name: "WittcoSales,Inc.",
    address: "35215 Rockford Way, Murietta, CA 92563 USA",
    representative: "Tom Wittmer",
    image: USAMuriettaLocationImg,
  },
  {
    id: 53,
    name: "Wretom Consilium AB",
    address: "Olof Dalins Vag 16, apt 1401, Stockholm, SE 112 52 Sweden",
    representative: "Lars-Ake Bergstrom",
    image: SwedenLocationImg,
  },
];
