import { useParams } from "react-router-dom";
import { SwitchPrimary } from "shared/components/SwitchPrimary";
import { MainTooltip } from "shared/components/MainTooltip";
import { Button } from "shared/components/Button";
import { useTranslation } from "hooks/useTranslation";
import styles from "./OptionRowItem.module.scss";
import {
  IconInfo,
  IconMonitor,
  IconFan,
  IconHeater,
  IconSiren,
  IconTopPreHeat,
  IconTypeCnArrowLoop,
  IconBottomPreHeat,
  IconTypeCnArrowLoopReverse,
  IconFluxHead,
  IconFiducialCorrection,
  IconBoardMaquette,
  IconBarcodeReader,
  IconElectromagneticPump,
} from "icons";
import IconFlowMeter from "assets/icons/icon-flow-meter.png";

const iconsByNameWhite = {
  iconMonitor: <IconMonitor color={"#fff"} />,
  iconFan: <IconFan color={"#fff"} />,
  iconHeater: <IconHeater color={"#fff"} />,
  iconSiren: <IconSiren color={"#fff"} />,
};

export const OptionRowItem = props => {
  const { translate } = useTranslation();
  const {
    icon = false,
    disabled,
    name,
    description,
    forwardTo,
    empty,
    checked,
    onToggle,
    tooltipContentRef,
    usedCount,
    handleClickOptionShowMore = () => {},
    isDuplicateFromFirst,
  } = props;

  const { company = "general" } = useParams();

  const iconByName = {
    iconMonitor: <IconMonitor color={!empty ? "#000" : undefined} />,
    iconFan: <IconFan color={!empty ? "#000" : undefined} />,
    iconHeater: <IconHeater color={!empty ? "#000" : undefined} />,
    iconSiren: <IconSiren color={!empty ? "#000" : undefined} />,
    iconTopPreHeat: <IconTopPreHeat color={!empty ? "#000" : undefined} />,
    iconTypeCnArrowLoop: (
      <IconTypeCnArrowLoop color={!empty ? "#000" : undefined} />
    ),
    iconBottomPreHeat: (
      <IconBottomPreHeat color={!empty ? "#000" : undefined} />
    ),
    iconTypeCnArrowLoopReverse: (
      <IconTypeCnArrowLoopReverse color={!empty ? "#000" : undefined} />
    ),
    iconFluxHead: <IconFluxHead color={!empty ? "#000" : undefined} />,
    iconFiducialCorrection: (
      <IconFiducialCorrection color={!empty ? "#000" : undefined} />
    ),
    iconBoardMaquette: (
      <IconBoardMaquette color={!empty ? "#000" : undefined} />
    ),
    iconFlowMeter: (
      <img width={48} height={48} src={IconFlowMeter} alt="flow-meter" />
    ),
    iconBarcodeReader: (
      <IconBarcodeReader color={!empty ? "#000" : undefined} />
    ),
    iconElectromagneticPump: (
      <IconElectromagneticPump color={!empty ? "#000" : undefined} />
    ),
  };

  const splittedForwardTo = !!forwardTo && forwardTo.split(" -> ");
  const forwardToObj = splittedForwardTo && {
    page: splittedForwardTo[0] ? splittedForwardTo[0].toLowerCase() : "",
    section: splittedForwardTo[1] ? splittedForwardTo[1].toLowerCase() : "",
    item: splittedForwardTo[2] ? splittedForwardTo[2].toLowerCase() : "",
  };

  const forwardLinkObj = forwardToObj && {
    pathname: `/${company}/${forwardToObj.page}`,
    state: { item: forwardToObj.item },
    search: `?name=${encodeURIComponent(forwardToObj.section)}`,
  };

  const handleToggle = event => {
    if (onToggle) {
      onToggle(event.target.checked);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftSec}>
        {icon && iconByName[icon]}
        {empty ? <span className={styles.rowLine}></span> : name}
      </div>
      <div className={styles.rightSec}>
        <MainTooltip
          title={
            <div ref={tooltipContentRef} className={styles.tooltipContent}>
              {icon && iconsByNameWhite[icon]}
              <h6 className={styles.title}>{name}</h6>
              <p className={styles.description}>{description || "..."}</p>
              <Button
                onClick={() => handleClickOptionShowMore(forwardLinkObj)}
                type={"transparentOutlined"}
                size="sm"
                disabled={!forwardToObj}
              >
                {translate("Show More")}
              </Button>
            </div>
          }
          color="#000000CC"
          padding="16px"
          fontSize="14px"
          fontWeight="400"
          borderRadius="8px"
          placement="top"
        >
          <div
            className={`${styles.infoIconContainer} ${
              disabled && empty && "noPointerEvents"
            }`}
          >
            <IconInfo color={!empty ? "#008ECC" : undefined} />
          </div>
        </MainTooltip>
        <SwitchPrimary
          onChange={handleToggle}
          checked={checked || false}
          disabled={disabled}
        />
        {!disabled && (
          <span className={styles.usedCountNumber}>{usedCount}</span>
        )}
      </div>
    </div>
  );
};
