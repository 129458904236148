import styles from "./RateComponent.module.scss";
import { IconRateStar } from "icons";

export const RateComponent = ({
  rate,
  totalStars = 5,
  passiveColor = "#7D7D7D",
}) => {
  const stars = [];

  for (let i = 1; i <= totalStars; i++) {
    stars.push(
      <IconRateStar key={i} color={i <= rate ? undefined : passiveColor} />
    );
  }

  return <div className={styles.container}>{stars}</div>;
};
