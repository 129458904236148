import { useEffect, useRef, useState } from "react";
import { SwiperSlide } from "swiper/react";
import { useTranslation } from "hooks/useTranslation";
import { Autoplay, Pagination } from "swiper/modules";
import { MainSwiper } from "shared/components/MainSwiper";
import { AddToConfiguratorElm } from "Layouts/AddToConfiguratorElm";
import { BANNER_DETAILS, BANNER_TXT_DETAILS } from "./constants";
import { MainTooltip } from "shared/components/MainTooltip";
import "swiper/css";
import "swiper/css/pagination";
import styles from "./Banner.module.scss";
import {
  IconBannerPattern,
  IconPauseTransparent,
  IconPlayTransparent,
} from "icons";

export const Banner = ({
  detailParameters,
  title,
  bannerDetailKey,
  currentProduct,
  isOpenConfigurator,
  setIsOpenConfigurator,
  isMarketSector,
}) => {
  const { translate } = useTranslation();
  const [isActiveAutoplay, setIsActiveAutoplay] = useState();
  const swiperRef = useRef(null);

  const bannerDetailsKey = isMarketSector ? "market" : "default";

  // HIDDEN MIGHT BE USED THEN
  const paginationIcons = {
    // 0: ReactDOMServer.renderToString(
    //   <IconSlideInfo className={styles.infoBullet} />
    // ),
    // 1: ReactDOMServer.renderToString(
    //   <IconSlideLocation className={styles.locationBullet} />
    // ),
  };

  const handleToggleAutoplay = () => {
    if (swiperRef.current.autoplay.running) {
      swiperRef.current.autoplay.stop();
      setIsActiveAutoplay(false);
    } else {
      swiperRef.current.autoplay.start();
      setIsActiveAutoplay(true);
    }
  };

  return (
    <div className={styles.swipeBannerBlock}>
      <MainTooltip
        title={translate("Autoplay")}
        color="black"
        padding="12px 16px"
        fontSize="14px"
        fontWeight="400"
      >
        <div className={styles.playPauseElm} onClick={handleToggleAutoplay}>
          {isActiveAutoplay ? (
            <IconPauseTransparent />
          ) : (
            <IconPlayTransparent />
          )}
        </div>
      </MainTooltip>
      <MainSwiper
        ref={swiperRef}
        direction={"vertical"}
        pagination={{
          clickable: true,
          renderBullet: (index, className) => {
            const iconHtml = paginationIcons[index];
            if (iconHtml) {
              return `<span class="${className} slider-custom-icon">${iconHtml}</span>`;
            }
            return `<span class="${className}"></span>`;
          },
        }}
        modules={[Autoplay, Pagination]}
        className={styles.bannerSwiper}
        autoplay={false}
      >
        {!!currentProduct && (
          <AddToConfiguratorElm
            isOpenConfigurator={isOpenConfigurator}
            setIsOpenConfigurator={setIsOpenConfigurator}
            currentProduct={currentProduct}
          />
        )}
        {BANNER_DETAILS[bannerDetailsKey]?.map((bannerDetail, idx) => (
          <SwiperSlide key={idx}>
            <div className={styles.container}>
              <img
                className={styles.bannerImage}
                src={bannerDetail.backgroundImg}
                alt={bannerDetail.title}
              />
              <div className={styles.overlayBlock} />
            </div>
          </SwiperSlide>
        ))}
      </MainSwiper>
      <div className={styles.contentBlock}>
        <div className={styles.detailParametersBlock}>
          {Object.entries(BANNER_TXT_DETAILS[bannerDetailKey].details).map(
            (param, idx) => {
              const desc = param[0];
              const value = param[1];
              return (
                <div key={idx} className={styles.param}>
                  <span className={styles.paramValue}>{value}</span>
                  <span className={styles.paramDesc}>{desc}</span>
                </div>
              );
            }
          )}
        </div>
        <div className={styles.titleBlock}>
          <IconBannerPattern className={styles.patternIcon} />
          {bannerDetailKey === "market" && (
            <p className={styles.sectorText}>{translate("Market Sector")}:</p>
          )}
          <p className={styles.bannerTitle}>{title}</p>
        </div>
      </div>
    </div>
  );
};
