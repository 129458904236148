import { SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { useTranslation } from "hooks/useTranslation";
import { MainSwiper } from "shared/components/MainSwiper";
import styles from "./Home.module.scss";
import { DynamicLink } from "shared/components/DynamicLink";
import RedLogo from "assets/logos/red-logo.png";
import ImageProcessor from "assets/images/home/image-processor.png";
// import FlexTronicsLogo from "assets/logos/flextronics-logo.png";

const HOME_SLIDES = [
  {
    image: ImageProcessor,
  },
];

export const Home = () => {
  const { translate } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.leftSec}>
        <div className={styles.header}>
          <img className={styles.logo} src={RedLogo} alt="Pillarhouse" />
          {/* HIDDEN THE COMPANY LOGO */}
          {/* <div className={styles.verticalLine}></div>
          <h4 className={styles.companyName}>
            <div className={styles.companyLogoContainer}>
              <img src={FlexTronicsLogo} alt="Flextronics" />
            </div>
          </h4> */}
        </div>
        <div className={styles.content}>
          <h2 className={styles.title}>
            <p className={styles.welcome}>{translate("Welcome")}</p>
            {/* CLOSED FLEXTRONICS */}
            {/* <p className={styles.name}>{translate("Flextronics")}</p> */}
          </h2>
          <p className={styles.description}>
            {translate(
              "Explore our innovative range of fluxing products designed to elevate your soldering experience. Trust in our commitment to quality and performance as we pave the way for seamless connections."
            )}
          </p>
        </div>
        <div className={styles.letStartBlock}>
          <p className={styles.letsStartTxt}>
            {translate(
              "Let's embark on a journey of superior soldering together!"
            )}
          </p>
          <DynamicLink
            to="/product?name=Fusion Layouts"
            className={styles.startBtn}
          >
            {translate("Lets Get Started")}
          </DynamicLink>
        </div>
      </div>
      <MainSwiper
        direction={"vertical"}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination]}
        className={styles.homeSlider}
      >
        {HOME_SLIDES.map((slide, idx) => (
          <SwiperSlide key={idx}>
            <div className={styles.slideContent}>
              <img className={styles.img} src={slide.image} alt={idx} />
            </div>
          </SwiperSlide>
        ))}
      </MainSwiper>
    </div>
  );
};
