import { useTranslation } from "hooks/useTranslation";
import { COUNTRY_LOCATION_DETAILS } from "constants";
import styles from "./Footer.module.scss";
import { IconPhone, IconEmail, IconLocation } from "icons";
import RedLogo from "assets/logos/red-logo.png";
import { LanguageSelect } from "shared/components/LanguageSelect";
import { Link } from "react-router-dom";
import { TestimonialsSlide } from "shared/components/TestimonialSlide";

export const Footer = () => {
  const { translate } = useTranslation();
  const addressCountries = ["uk", "us", "china", "mexico"];
  const currentYear = new Date().getFullYear();

  return (
    <div className={styles.container}>
      <div className={styles.othersSayingBlock}>
        <h2 className={styles.sectionTitle}>
          {translate("What Others Are Saying")}
        </h2>
        <TestimonialsSlide />
      </div>
      <div className={styles.addressesBlock}>
        {addressCountries.map((country, idx) => (
          <div className={styles.addressColumn} key={idx}>
            <div className={styles.flagRow}>
              <span className={styles.flag}>
                {COUNTRY_LOCATION_DETAILS[country].flag}
              </span>
              <span className={styles.countryName}>
                {translate(COUNTRY_LOCATION_DETAILS[country].name)}
              </span>
            </div>
            <a
              href={`tel:${COUNTRY_LOCATION_DETAILS[country].phone}`}
              className={styles.row}
            >
              <span className={styles.iconBlock}>
                <IconPhone />
              </span>
              <span className={styles.detailInfo}>
                {COUNTRY_LOCATION_DETAILS[country].phone}
              </span>
            </a>
            <a
              href={`mailto:${COUNTRY_LOCATION_DETAILS[country].email}`}
              className={styles.row}
            >
              <span className={styles.iconBlock}>
                <IconEmail />
              </span>
              <span className={styles.detailInfo}>
                {COUNTRY_LOCATION_DETAILS[country].email}
              </span>
            </a>
            <a
              href={`https://maps.google.com/?q=${encodeURIComponent(
                COUNTRY_LOCATION_DETAILS[country].address
              )}`}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.row}
            >
              <span className={styles.iconBlock}>
                <IconLocation />
              </span>
              <span className={styles.detailInfo}>
                {COUNTRY_LOCATION_DETAILS[country].address}
              </span>
            </a>
          </div>
        ))}
      </div>
      <div className={styles.generalFootBlock}>
        <Link to="/" className={styles.logo}>
          <img src={RedLogo} alt="Pillarhouse" />
        </Link>
        <div className={styles.rightsReserved}>
          © {currentYear} Pillarhouse International • All Rights Reserved
        </div>
        <LanguageSelect />
      </div>
    </div>
  );
};
