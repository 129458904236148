export const chosenProductsSelector = state =>
  state.configuratorReducer.chosenProducts;

export const productsListSelector = state =>
  state.configuratorReducer.productsList;

export const cellsListSelector = state => state.configuratorReducer.cellsList;

export const selectedLineItemSelector = state =>
  state.configuratorReducer.selectedLineItem;

export const selectedCellToViewSelector = state =>
  state.configuratorReducer.selectedCellToView;

export const configuratorReducerSelector = state => state.configuratorReducer;
