import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import configuratorSlice from "./slices/configuratorSlice";

function useRouteBasedPersistConfig() {
  const isHashRouting = window.location.hash.length > 0;

  const routeName = isHashRouting
    ? window.location.pathname?.split("/")?.filter(str => str)?.[0] || "default"
    : window.location.pathname.replace(/\//g, "") || "default";

  const configuratorPersistConfig = {
    key: `configurator-${routeName}`,
    storage: storage,
  };

  const rootReducer = combineReducers({
    configuratorReducer: persistReducer(
      configuratorPersistConfig,
      configuratorSlice
    ),
  });

  return rootReducer;
}

export function useStore() {
  const rootReducer = useRouteBasedPersistConfig();

  const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [
            "persist/PERSIST",
            "persist/REHYDRATE",
            "persist/PAUSE",
            "persist/PAUSE",
            "persist/PURGE",
            "persist/REGISTER",
          ],
        },
      }),
  });

  const persistor = persistStore(store);

  return { store, persistor };
}
