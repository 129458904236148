import React from "react";
import { SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { TESTIMONIALS } from "./constants";
import { TestimonialItem } from "../TestimonialItem";
import styles from "./TestimonialSlide.module.scss";
import { MainSwiper } from "../MainSwiper";

export const TestimonialsSlide = ({ testimonials = TESTIMONIALS }) => {
  return (
    <MainSwiper
      slidesPerView={2}
      spaceBetween={30}
      breakpoints={{
        1400: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        320: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
      }}
      pagination={{
        clickable: true,
      }}
      modules={[Autoplay, Pagination]}
      className={styles.testimonialSwiper}
    >
      {testimonials.map((testimonial, index) => (
        <SwiperSlide key={index} className={styles.testimonialSlide}>
          <TestimonialItem key={index} {...testimonial} />
        </SwiperSlide>
      ))}
    </MainSwiper>
  );
};
