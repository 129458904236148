export const EMPTY_OPTIONS = [
  { id: 1, disabled: true, isEmpty: true, icon: "iconMonitor" },
  { id: 2, disabled: true, isEmpty: true, icon: "iconMonitor" },
  { id: 3, disabled: true, isEmpty: true, icon: "iconFan" },
  { id: 4, disabled: true, isEmpty: true, icon: "iconFan" },
  { id: 5, disabled: true, isEmpty: true, icon: "iconHeater" },
  { id: 6, disabled: true, isEmpty: true, icon: "iconSiren" },
  { id: 7, disabled: true, isEmpty: true, icon: "iconMonitor" },
  { id: 8, disabled: true, isEmpty: true, icon: "iconMonitor" },
  { id: 9, disabled: true, isEmpty: true, icon: "iconFan" },
  { id: 10, disabled: true, isEmpty: true, icon: "iconFan" },
  { id: 11, disabled: true, isEmpty: true, icon: "iconHeater" },
];

export const EMPTY_OTHER_OPTIONS = [
  {
    id: 1,
    defaultChecked: true,
    disabled: true,
    isEmpty: true,
    lineWidth: 236,
  },
  {
    id: 2,
    defaultChecked: true,
    disabled: true,
    isEmpty: true,
    lineWidth: 236,
  },
  {
    id: 3,
    defaultChecked: true,
    disabled: true,
    isEmpty: true,
    lineWidth: 236,
  },
  {
    id: 4,
    defaultChecked: false,
    disabled: true,
    isEmpty: true,
    lineWidth: 140,
  },
  {
    id: 5,
    defaultChecked: false,
    disabled: true,
    isEmpty: true,
    lineWidth: 140,
  },
];
