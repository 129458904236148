export const TESTIMONIALS = [
  {
    avatar: null,
    rate: 4,
    companyName: "Company Name",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    date: "18 December",
  },
  {
    avatar: null,
    rate: 4,
    companyName: "Company Name",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    date: "18 December",
  },
  {
    avatar: null,
    rate: 4,
    companyName: "Company Name",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    date: "18 December",
  },
  {
    avatar: null,
    rate: 4,
    companyName: "Company Name",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    date: "18 December",
  },
];
