import { PrimaryDialog } from "shared/components/PrimaryDialog";
import { useTranslation } from "hooks/useTranslation";
import { CellItem } from "../CellItem";
import styles from "./SelectCellDialog.module.scss";
import { EMPTY_PRODUCT_CELL, PRODUCT_CELLS } from "reduxStore/mockData";

export const SelectCellDialog = props => {
  const { translate } = useTranslation();
  const { dialogRef, dialogData, handleSelectCell } = props;

  return (
    <PrimaryDialog
      {...props}
      dialogRef={dialogRef}
      className={styles.container}
    >
      <h3 className={styles.title}>{translate("CHOOSE CELL")}</h3>
      <div
        onClick={() =>
          handleSelectCell({
            lineItemId: dialogData.lineItemId,
            data: dialogData,
            selectedCell: EMPTY_PRODUCT_CELL,
          })
        }
        className={styles.cellBlock}
      >
        <CellItem
          cell={EMPTY_PRODUCT_CELL}
          size="md"
          hasHoverState
          className={styles.cell}
        />
        <div className={styles.nameBlock}>
          <p className={styles.name}>{translate(EMPTY_PRODUCT_CELL.name)}</p>
          <span className={styles.with}>
            {translate(EMPTY_PRODUCT_CELL.with)}
          </span>
        </div>
      </div>
      <div className={styles.line}></div>
      <div className={styles.productCellsBlock}>
        {PRODUCT_CELLS.map((cell, idx) => (
          <div
            onClick={() =>
              handleSelectCell({
                lineItemId: dialogData.lineItemId,
                selectedCell: cell,
                data: dialogData,
              })
            }
            key={idx}
            className={styles.cellBlock}
          >
            <CellItem
              cell={cell}
              size="md"
              hasHoverState
              className={styles.cell}
            />
            <div className={styles.nameBlock}>
              <p className={styles.name}>{translate(cell.name)}</p>
              <span className={styles.with}>{translate(cell.with)}</span>
            </div>
          </div>
        ))}
      </div>
    </PrimaryDialog>
  );
};
