import React from "react";
import { RateComponent } from "../RateComponent";
import styles from "./TestimonialItem.module.scss";

export const TestimonialItem = ({ avatar, companyName, rate, text, date }) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.header}>
          {!avatar && <span className={styles.emptyAvatar} />}
          {companyName && (
            <div className={styles.companyName}>{companyName}</div>
          )}
        </div>
        <RateComponent rate={rate} />
        <div className={styles.text}>{text}</div>
        {date && <div className={styles.date}>{date}</div>}
      </div>
    </div>
  );
};
