import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import ProductImage from "assets/images/products/productImg.png";
import DetailImg from "assets/images/products/productDetailImg.png";
import { apiClient } from "services/api";
import { PRODUCT_CELLS, PRODUCT_LIST_FROM_BACK } from "../mockData";
import { formatDurationToMinsSeconds } from "utils/dateUtils";

export const fetchDeviceListAsync = createAsyncThunk(
  "configurator/fetchDeviceList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiClient.get("/device/list");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const chosenProductsSelector = state =>
  state.configurator.chosenProducts;

const initialState = {
  productsList: PRODUCT_LIST_FROM_BACK,
  cellsList: PRODUCT_CELLS,
  chosenProducts: [],
  selectedLineItem: null,
  selectedCellToView: null,
};

const configuratorSlice = createSlice({
  name: "configurator",
  initialState,
  reducers: {
    addProduct: state => {
      const newItem = {
        lineItemId: uuidv4(),
        product: null,
        cells: [],
      };
      state.chosenProducts.push(newItem);
    },
    deleteProductById: (state, action) => {
      const { id } = action.payload;
      state.chosenProducts = state.chosenProducts.filter(
        prod => prod.lineItemId !== id
      );
    },
    selectProduct: (state, action) => {
      const { lineItemId, product, cellsCount } = action.payload;
      const item = state.chosenProducts.find(
        item => item.lineItemId === lineItemId
      );
      if (item) {
        item.product = product;
        if (cellsCount) {
          item.cells = new Array(cellsCount).fill(null);
        }
      }
    },
    addNewProductWithSelect: (state, action) => {
      const newItem = {
        lineItemId: uuidv4(),
        product: null,
        cells: [],
      };
      const { product, cellsCount } = action.payload;

      newItem.product = product;
      if (cellsCount) {
        newItem.cells = new Array(cellsCount).fill(null);
      }
      state.chosenProducts.push(newItem);
    },
    addCellToProduct: (state, action) => {
      const { lineItemId, selectedCell, idx, isDuplicateFromFirst } =
        action.payload;
      const productIndex = state.chosenProducts.findIndex(
        item => item.lineItemId === lineItemId
      );

      if (productIndex !== -1) {
        const product = state.chosenProducts[productIndex];
        if (product.product && !product.cells[idx]) {
          const newCells = product.cells.map((cell, cellIdx) => {
            if (cellIdx === idx) {
              return {
                ...selectedCell,
                id: uuidv4(),
                optional_features: isDuplicateFromFirst
                  ? product.cells[0]?.optional_features
                  : product.product.optional_features,
                other_options: isDuplicateFromFirst
                  ? product.cells[0]?.other_options
                  : product.product.other_options,
                isDuplicateFromFirst,
              };
            }
            return cell;
          });

          state.chosenProducts[productIndex].cells = newCells;
          if (
            state.selectedLineItem &&
            state.selectedLineItem.lineItemId === lineItemId
          ) {
            state.selectedLineItem.cells = newCells;
          }
        }

        if (idx === 0) {
          const updatedCells = product.cells.map((cell, cellIdx) => {
            if (cellIdx !== 0 && product.cells[cellIdx]?.isDuplicateFromFirst) {
              return {
                ...product.cells[0],
                id: uuidv4(),
                optional_features: product.product.optional_features,
                other_options: product.product.other_options,
              };
            }
            return cell;
          });

          state.chosenProducts[productIndex].cells = updatedCells;
          if (
            state.selectedLineItem &&
            state.selectedLineItem.lineItemId === lineItemId
          ) {
            state.selectedLineItem.cells = updatedCells;
          }
        }
      }
    },

    toggleDuplicateFlag: (state, action) => {
      const { lineItemId, cellIndex, shouldDuplicate } = action.payload;

      const productIndex = state.chosenProducts.findIndex(
        p => p.lineItemId === lineItemId
      );
      if (productIndex !== -1) {
        const product = state.chosenProducts[productIndex];
        if (product.cells.length > cellIndex) {
          product.cells[cellIndex].isDuplicateFromFirst = shouldDuplicate;
          state.selectedCellToView.isDuplicateFromFirst = shouldDuplicate;

          if (shouldDuplicate) {
            const firstCellConfig = product.cells[0];
            product.cells[cellIndex] = {
              ...product.cells[cellIndex],
              id: product.cells[cellIndex]?.id || uuidv4(),
              optional_features: firstCellConfig.optional_features,
              other_options: firstCellConfig.other_options,
            };
            state.selectedCellToView = {
              ...state.selectedCellToView,
              isDuplicateFromFirst: shouldDuplicate,
              optional_features: firstCellConfig.optional_features,
              other_options: firstCellConfig.other_options,
            };
          }
        }
      }
    },

    setOptionalFeaturesForCell: (state, action) => {
      const {
        newCheckedValue,
        selectedLineItem,
        selectedCellToView,
        optionalFeature,
      } = action.payload;

      const lineItemIndex = state.chosenProducts.findIndex(
        item => item.lineItemId === selectedLineItem.lineItemId
      );

      if (lineItemIndex !== -1) {
        const cells = state.chosenProducts[lineItemIndex].cells;
        const cellIndex = cells.findIndex(
          cell => cell?.id === selectedCellToView.id
        );

        if (cellIndex !== -1) {
          const updatedOptionalFeatures = cells[
            cellIndex
          ].optional_features.map(feature => {
            if (feature?.id === optionalFeature?.id) {
              return { ...feature, configured: newCheckedValue };
            }
            return feature;
          });

          const updatedCells = cells.map((cell, idx) => {
            if (idx === cellIndex) {
              return { ...cell, optional_features: updatedOptionalFeatures };
            }
            return cell;
          });
          state.selectedLineItem.cells = updatedCells;

          if (cellIndex === 0) {
            updatedCells.forEach((cell, idx) => {
              if (idx !== 0 && cell?.isDuplicateFromFirst) {
                const duplicatedFeatures = updatedOptionalFeatures.map(
                  feature => {
                    return { ...feature };
                  }
                );
                updatedCells[idx] = {
                  ...cell,
                  optional_features: duplicatedFeatures,
                };
              }
            });
          }

          state.chosenProducts[lineItemIndex].cells = updatedCells;

          if (
            state.selectedCellToView &&
            state.selectedCellToView.id === cells[cellIndex].id
          ) {
            state.selectedCellToView.optional_features =
              updatedOptionalFeatures;
          }
        }
      }
    },

    setOtherOptionForCell: (state, action) => {
      const {
        newCheckedValue,
        selectedLineItem,
        selectedCellToView,
        otherOption,
      } = action.payload;
      const lineItemIndex = state.chosenProducts.findIndex(
        item => item?.lineItemId === selectedLineItem?.lineItemId
      );
      if (lineItemIndex !== -1) {
        const cells = state.chosenProducts[lineItemIndex].cells;
        const cellIndex = cells.findIndex(
          cell => cell?.id === selectedCellToView?.id
        );
        if (cellIndex !== -1) {
          const updatedOtherOptions = cells[cellIndex].other_options.map(
            opt => {
              if (opt.id === otherOption.id) {
                return { ...opt, configured: newCheckedValue };
              }
              return opt;
            }
          );

          const updatedCells = cells.map((cell, idx) => {
            if (idx === cellIndex) {
              return { ...cell, other_options: updatedOtherOptions };
            }
            return cell;
          });
          state.selectedLineItem.cells = updatedCells;

          if (cellIndex === 0) {
            updatedCells.forEach((cell, idx) => {
              if (idx !== 0 && cell?.isDuplicateFromFirst) {
                const duplicateOptions = updatedOtherOptions.map(opt => {
                  return { ...opt };
                });
                updatedCells[idx] = {
                  ...cell,
                  other_options: duplicateOptions,
                };
              }
            });
          }

          state.chosenProducts[lineItemIndex].cells = updatedCells;

          if (
            state.selectedCellToView &&
            state.selectedCellToView.id === cells[cellIndex].id
          ) {
            state.selectedCellToView.other_options = updatedOtherOptions;
          }
        }
      }
    },

    resetConfigurator: state => {
      state.chosenProducts = [];
      state.selectedCellToView = null;
      state.selectedLineItem = null;
    },
    setSelectedLineItem: (state, action) => {
      state.selectedLineItem = action.payload;
    },
    setSelectedCellToView: (state, action) => {
      if (action.payload === null) {
        state.selectedCellToView = action.payload;
      } else {
        const { id, index } = action.payload;
        const currentCell = state.selectedLineItem.cells.find(
          cell => cell?.id === id
        );
        state.selectedCellToView = { ...(currentCell || {}), index };
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchDeviceListAsync.fulfilled, (state, action) => {
        state.productsList = action.payload.map((prod, idx) => {
          const cells = new Array(prod.cellnum).fill({
            id: uuidv4(),
            img: null,
            name: null,
          });
          return {
            ...prod,
            img: ProductImage,
            id: prod.device_id,
            name: prod.device_name,
            detailImg: DetailImg,
            standard_features: prod.standard_features.map((feat, idx) => ({
              ...feat,
              id: uuidv4(),
              title: "Integral Machine Mounted Monitor & PC",
              description:
                "Lorem ipsum dolor sit amet consectetur. Faucibus ultrices odio in viverra velit sagittis phasellus. Bibendum pellentesque sollicitudin sit massa felis in viverra convallis. Turpis risus sem sed amet arcu. Dolor diam faucibus amet vel vitae eu tortor feugiat. Risus hendrerit venenatis semper nulla sed. Tincidunt pellentesque vestibulum diam convallis. Eget non vestibulum enim netus ut sed id a sed. Nibh ac leo arcu turpis eget id risus. Tincidunt mi integer molestie lorem.",
            })),
            optional_features: prod.optional_features.map(feat => ({
              ...feat,
              id: uuidv4(),
            })),
            other_options: prod.other_options.map(opt => ({
              ...opt,
              id: uuidv4(),
            })),
            options: [
              {
                id: 1,
                name: "Units Per Hour",
                measurementUnit: `${prod.units_per_hour} u/h`,
                progress: (Number(prod.units_per_hour) / 10) * 100,
              },
              {
                id: 2,
                name: "Fluxes Per Hour",
                measurementUnit: `${prod.fluxes_per_min} f/m`,
                progress: (Number(prod.fluxes_per_min) / 20) * 100,
              },
              {
                id: 3,
                name: "Inclination",
                measurementUnit: `${prod.inclination}°`,
                progress: Number(prod.inclination),
              },
              {
                id: 4,
                name: "Start Time",
                measurementUnit: formatDurationToMinsSeconds(prod.start_time),
                progress: (prod.start_time / 600) * 100,
              },
              {
                id: 5,
                name: "Defects Per 100 Unit",
                measurementUnit: `${prod.defects_per_unit}/100 d`,
                progress: Number(prod.defects_per_unit),
              },
              {
                id: 6,
                name: "Energy Class",
                measurementUnit: "A++",
                progress: 100,
              },
            ],
            cells,
          };
        });
      })
      .addCase(fetchDeviceListAsync.rejected, (state, action) => {
        console.error(action.payload);
      });
  },
});

export const {
  addProduct,
  deleteProductById,
  selectProduct,
  addCellToProduct,
  resetConfigurator,
  setSelectedLineItem,
  setSelectedCellToView,
  setOptionalFeaturesForCell,
  setOtherOptionForCell,
  addNewProductWithSelect,
  toggleDuplicateFlag,
} = configuratorSlice.actions;

export default configuratorSlice.reducer;
